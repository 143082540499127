import {
  initialState,
  Actions,
  State,
  GET_USER_MAIN_INFORMATION,
  GET_USER_MAIN_INFORMATION_SUCCESS,
  GET_USER_MAIN_INFORMATION_ERROR,
  RESET_USER_MAIN_INFORMATION,
} from '../../types/user/get-information';

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_USER_MAIN_INFORMATION:
      return {
        data: state.data,
        states: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case GET_USER_MAIN_INFORMATION_SUCCESS:
      return {
        data: action.payload,
        states: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case GET_USER_MAIN_INFORMATION_ERROR:
      return {
        data: null,
        states: {
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    case RESET_USER_MAIN_INFORMATION:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
