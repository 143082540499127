import { combineReducers } from 'redux';
import information from './information';
import updateInformation from './update-information';
import customization from './customization';
import changePassword from './change-password';

const user = combineReducers({
  information,
  updateInformation,
  customization,
  changePassword,
});

export default user;
